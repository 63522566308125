import axios from 'axios';
import { mockedAdvance } from './mocks';
import { alkymindClient } from './alkymindClient';
import { alkymetricsClient } from './alkymetricsClient';
import { screeningsClient } from './screeningsClient';
import { meetingsClient } from './meetingsClient';
import { removeEmptyStringsFromObject } from '../utils/removeEmptyStringsFromObject';
import { alkymetricsV2BaseURL } from './config';

const events = [
  {
    id: 0,
    title: 'tem event',
    allDay: false,
    start: new Date(2021, 8, 7, 10, 30, 0, 0),
    end: new Date(2021, 5, 7, 11, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 1,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 19,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 11,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 12,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 13,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 14,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 7, 11, 30, 0, 0),
    end: new Date(2021, 5, 7, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 15,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 29, 11, 30, 0, 0),
    end: new Date(2021, 5, 29, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 2,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 6, 12, 30, 0, 0),
    end: new Date(2021, 5, 6, 13, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 3,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 6, 11, 30, 0, 0),
    end: new Date(2021, 5, 6, 16, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 4,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 5, 10, 30, 0, 0),
    end: new Date(2021, 5, 5, 11, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 5,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 5, 11, 30, 0, 0),
    end: new Date(2021, 5, 5, 12, 30, 0, 0),
    description: 'A Fake Description',
  },
  {
    id: 5,
    title: 'All Day Event very long title',
    allDay: false,
    start: new Date(2021, 5, 22, 11, 30, 0, 0),
    end: new Date(2021, 5, 22, 12, 30, 0, 0),
    description: 'A Fake Description',
  },
];

export async function getProfile() {
  return alkymetricsClient.get('/users/me').then(response => {
    return response.data;
  });
}

export async function getProfileAdvance() {
  const response = await alkymetricsClient.get('/users/advance/me');
  return response.data;
}

export async function getMaintenance() {
  return alkymetricsClient.get('/maintenance').then(response => {
    return response.data;
  });
}

export const getRecording = meetings => {
  return meetingsClient.get('/zoom/meetings/recordings', { params: { meetings } }).then(response => {
    return response.data?.filter(data => data.account_id);
  });
};

export async function getGenders() {
  return alkymetricsClient.get('/gender').then(response => {
    return response.data;
  });
}
export async function getTechnologies(visibility = '') {
  return alkymetricsClient.get('/technology', { params: { visible: visibility } }).then(response => {
    return response.data;
  });
}
export async function getLanguages() {
  return alkymetricsClient.get('/language').then(response => {
    return response.data;
  });
}
export async function getLevels() {
  return alkymetricsClient.get('/level').then(response => {
    return response.data;
  });
}

export async function saveProfile(data) {
  try {
    await alkymetricsClient.post('/users/me', removeEmptyStringsFromObject(data));
  } catch (err) {
    throw new Error(err);
  }
}

export async function changeDisabledAccount(id, accountDisabled) {
  return alkymetricsClient
    .patch(`/users/disabledAccount/${id}`, { accountDisabled })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw new Error(err);
    });
}

export const getGoogleValidAccount = async email => await alkymetricsClient.get(`/users/resetPassword/${email}`);

export async function postAvailability(available) {
  return alkymetricsClient.post('/users/me/availability', available).then(response => {
    return response.data;
  });
}

export async function getEvents() {
  return Promise.resolve(events);
}

export async function postImageProfile(data) {
  const response = await alkymetricsClient.post('/users/me/picture', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response.data;
}

export async function savePdfProfile(data) {
  const response = await alkymetricsClient.post('/users/me/cv', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response.data;
}

export const getAdvance = async id => {
  try {
    const { data } = await alkymindClient.get(`/profiles/${id}`);
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getAdvanceTechnology = async id => {
  try {
    const { data } = await alkymindClient.get(`/technology/${id}`);
    return data;
  } catch (err) {
    return {};
  }
};

export const getProfileNews = async () => {
  try {
    const { data } = await alkymetricsClient.get('/users/news');
    return data;
  } catch (err) {
    return {};
  }
};
export const getMentor = async id => {
  try {
    const { data } = await alkymetricsClient.get(`/mentor/${id}`);
    return data;
  } catch (err) {
    return {};
  }
};

export const getProfileTeam = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/groups/me`);
    return data;
  } catch (err) {
    return mockedAdvance;
  }
};

export const getProfileNotifications = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/notification/me`);
    return data;
  } catch (error) {
    return [];
  }
};

export const DeleteProfileChallenge = async id => {
  try {
    const { data } = await alkymetricsClient.delete(`/challenge/${id}`);
    return data;
  } catch (error) {
    return {};
  }
};

export const getProfileAnnouncements = async ids => {
  try {
    const { data } = await alkymetricsClient.get(`/announcement/bytechnologies/${ids}`);
    return data;
  } catch (error) {
    return [];
  }
};
export const getAnnoucements = async (latest = false) => {
  try {
    const { data } = await alkymetricsClient.get(`/announcement?latest=${latest}`);
    return data;
  } catch (error) {
    return [];
  }
};

export const getScreenings = async type => {
  try {
    const { data } = await screeningsClient.get('/screenings/available', {
      params: {
        type,
      },
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const postAlkymerEnrollment = async requestData => {
  try {
    const { data } = await alkymetricsClient.post(`/challenge`, requestData);
    return data;
  } catch (error) {
    return {};
  }
};
export const postScreeningInvitation = async () => {
  const { data } = await alkymetricsClient.post(`/assessmentInvitation/accept`);
  return data;
};

export const getScreeningInvitation = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/assessmentInvitation/me`);
    return data;
  } catch (error) {
    return [];
  }
};
export const getProfileChallenges = async () => {
  try {
    const { data } = await alkymetricsClient.get(`/challenge/me`);
    return data;
  } catch (error) {
    return [];
  }
};
export const getTests = async () => {
  try {
    const { data } = await screeningsClient.get(`/screenings/available`);
    return data;
  } catch (error) {
    return [];
  }
};

export const patchProfileChallenge = async (id, repositoryUrl) => {
  return await alkymetricsClient
    .patch(`/challenge/${id}/url`, repositoryUrl)
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
};

export const getAccelerationInfo = async (type, technologyId, companyId) => {
  try {
    const { data } = await alkymetricsClient.get('/assets', {
      params: {
        type,
        technology: technologyId,
        company: companyId,
      },
    });
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getIntegratorsInfo = async () => {
  try {
    const { data } = await alkymetricsClient.get('/users/me/integrators');
    return data;
  } catch (error) {
    return {};
  }
};

export const getWhiteLabel = async (language = 'es') => {
  const { data } = await alkymetricsClient.get(`/whitelabel/me`, {
    headers: {
      ...(process.env.RAZZLE_RUNTIME_ORIGIN_DEV ? { OriginDev: process.env.RAZZLE_RUNTIME_ORIGIN_DEV } : {}),
      language,
    },
  });
  return data;
};

export const getColorAndLogoCompany = async invitationShortId => {
  const data = await alkymetricsClient.get(`/recruitSessionStage/colorAndLogoCompanyShortId/${invitationShortId}`, {
    headers: {
      ...(process.env.RAZZLE_RUNTIME_ORIGIN_DEV ? { OriginDev: process.env.RAZZLE_RUNTIME_ORIGIN_DEV } : {}),
    },
  });
  return data;
};

export const getVideoUrl = async () => {
  try {
    const { data } = await alkymetricsClient.get('/users/upload/video');
    return data;
  } catch (error) {
    return {};
  }
};

export const postSoftSkillVideo = async requestData => {
  try {
    const { videoUrl, fmData } = requestData;
    const { data } = await axios.put(videoUrl, Buffer.from(fmData), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });
    return data;
  } catch (error) {
    return { err: error };
  }
};

export const postSoftSkillConfirmation = async requestData => {
  try {
    const { videoId, videoType } = requestData;
    const { data } = await alkymetricsClient.post('/users/upload/video', {
      videoId,
      videoType,
    });
    return data;
  } catch (error) {
    return { err: error };
  }
};

export const getChallengeAdvance = async id => {
  try {
    const { data } = await alkymindClient.get(`/screening/byChallenge/${id}`);
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getScreeningAdvance = async id => {
  try {
    const { data } = await alkymindClient.get(`/screening/byScreeningInstance/${id}`);
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getMyScreenings = async () => {
  try {
    const { data } = await screeningsClient.get('/screenings/me');
    return data;
  } catch (err) {
    return { error: err };
  }
};

export const getStudyArea = async () => {
  try {
    const { data } = await alkymetricsClient.get('/studyArea');
    return data;
  } catch (error) {
    return [];
  }
};

export const getUserProgress = async () => {
  const { data } = await alkymindClient.get(`/personProgress/me`);
  return data;
};

export const updateAdvanceVisibility = async obj => {
  const { data } = await alkymindClient.patch('/personProgress/me', obj);
  return data;
};

export const resendEmailVerifyAccount = async () => {
  const { data } = await alkymetricsClient.get('/resend/email?platform=CAMPUS');
  return data;
};

export const getUserHasPendingInvitations = async () => {
  const { data } = await alkymetricsClient.get('/assessmentInvitation/pending/me');
  return data;
};

export const acceptPendingInvitations = async body => {
  const { data } = await alkymetricsClient.post('/assessmentInvitation/accept/pending', body);
  return data;
};

export const createPendingInvitation = async body => {
  const { data } = await alkymetricsClient.post('/assessmentInvitation/pending', body);
  return data;
};

export const createAcceptedInvitation = async body => {
  const { data } = await alkymetricsClient.post('/assessmentInvitation/create/accepted', body);
  return data;
};

export const getTermsAndConditions = async () => {
  const { data } = await alkymetricsClient.get('/terms-and-conditions', {
    params: {
      platform: 'CAMPUS',
    },
  });
  return data;
};

export const hideDeletedInvitation = async body => {
  const { data } = await alkymetricsClient.post('/assessmentInvitation/hide', body);
  return data;
};

export const getEvaluationCertificate = async invitationId => {
  const { data } = await alkymetricsClient.get(`/certificate/${invitationId}/get`);
  return data;
};

export const getExclusiveInvitation = async invitationId => {
  const { data } = await axios.get(
    `${alkymetricsV2BaseURL}/recruitSessionStagePublic/isExclusive?invitationId=${invitationId}`,
  );
  return data;
};

export const getCertificatePublicUrl = async () => {
  const { data } = await alkymetricsClient.get('/users/certificates/public-url');
  return data;
};

export const qrCodeGenerator = async body => {
  const res = await alkymetricsClient.post('/qrcodeGenerator', body, { responseType: 'arraybuffer' });
  return {
    data: res.data,
    fileType: res.headers['content-type'],
    fileName: res.headers['content-disposition'].split('filename=')[1],
  };
};
export const getUserChallengeCertificates = async userId => {
  const { data } = await screeningsClient.get(`/screenings/instances/certificates/${userId}`);
  return data;
};
export const updateChallengesCertificates = async ({ userId, screeningInstances }) => {
  const { data } = await screeningsClient.patch(`/screenings/instances/certificates/${userId}`, screeningInstances);
  return data;
};
